import { Inject, Injectable } from '@angular/core';
import { RECOMMENDATIONS_GET_GAMES, RECOMMENDATIONS_OPEN_GAME, RECOMMENDATIONS_OPEN_HOME_PAGE } from '../http/base-http.service';
import { HttpClient } from '@angular/common/http';
import { HOST_NAME } from 'src/app/global.tokens';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { RecommendedGame } from './recommendation.models';
import { Apollo, gql } from 'apollo-angular';
import { catchError, share, switchMap } from 'rxjs/operators';
import { GameItem } from '../../apollo/models/base-models';
import { EMPTY, from } from 'rxjs';

const query = gql`query GameSearchList($searchIds: [String!]){
    games(where: {gameid_in: $searchIds}) {
      id
      name,
      gameid,
      systemName,
      thumbnail {
        url
      }
      thumbnailDesktopSquare{
          url(transformation: {
            image: { resize: { width: 206, height: 206, fit: clip } }
            document: { output: { format: webp } }
          })
        }
        thumbnailDesktopRectangular{
          url(transformation: {
            image: { resize: { width: 236, height: 177, fit: clip } }
            document: { output: { format: webp } }
          })
        }
      }
    }
    `;

@Injectable({
  providedIn: 'root'
})
export class RecommendationService extends BaseFirebaseService {

  constructor(
    http: HttpClient,
    afAuth: AngularFireAuth,
    afs: AngularFirestore,
    private apollo: Apollo,
    @Inject(HOST_NAME) public hostName
  ) {
    super(http, afAuth, afs, hostName);
  }

  public sendGameOpenedEvent(gameId: string) {
    return this.post<any, any>(RECOMMENDATIONS_OPEN_GAME + gameId, {});
  }

  public sendHomePageOpenedEvent() {
    return this.post<any, any>(RECOMMENDATIONS_OPEN_HOME_PAGE, {});
  }

  public getRecommendations(gameId?: string) {
    return this.get<RecommendedGame[]>(gameId != null ? RECOMMENDATIONS_GET_GAMES + '/' + gameId : RECOMMENDATIONS_GET_GAMES, true).pipe(
      share(),
      switchMap(
        (games: RecommendedGame[]) => {
          if (games && games.length > 0) {
            const queryRef = this.apollo
              .watchQuery<{ games: GameItem[] }>({
                query: query,
                variables: {
                  searchIds: games.map(game => game.id)
                }
              });
            return from(queryRef.valueChanges);
          } else {
            return EMPTY;
          }
        }
      ),
      catchError(error => {
        console.log(error);
        return EMPTY;
      })
    );
  }
}
